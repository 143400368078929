<template>
<!-- start page-wrapper -->

<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header />
    <!-- :::::: End Header Section ::::::  -->
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="page-breadcrumb__nav active">Signup</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row">
                <div class="col-12 offset-md-3">
                    <div class="section-content m-b-40">
                        <h5 class="section-content__title text-center">REGISTER</h5>
                    </div>
                </div>
                <!-- Start Login Area -->
                <div class="col-lg-6 col-12 offset-md-3">
                    <div class="login-form-container card">
                        <div class="login-register-form">
                             <ValidationObserver ref="form">
                            <form action="#" method="post">
                                <div class="form-box__single-group">
                                    <label for="form-register-username">Username *</label>
                                    <validation-provider rules="required|alpha_spaces|min:3" v-slot="{ errors }">
                                        <input type="text" id="fname" v-model="fields.fullname" name="name" placeholder="Enter Your Fullname here..">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                                <div class="form-box__single-group">
                                    <label for="form-uregister-sername-email">Email address *</label>
                                    <validation-provider rules="required|email" v-slot="{ errors }">
                                        <input type="email" id="email" v-model="fields.email" name="email" placeholder="Your email here..">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>

                                <div class="form-box__single-group">
                                    <label for="form-uregister-sername-email">Mobile Number *</label>
                                    <validation-provider rules="required|integer|min:10|max:10" v-slot="{ errors }">
                                        <input type="number" id="mobile" v-model="fields.mobile" name="mobile" placeholder="Your mobile number here..">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                                <div class="form-box__single-group">
                                    <label for="form-uregister-sername-email">Country</label>
                                    <input type="text" id="Country" v-model="fields.country" name="Country" disabled placeholder="Enter your country here..">
                                </div>
                                <div class="form-box__single-group m-b-20">
                                    <label for="form-register-username-password">Password *</label>
                                    <div class="password__toggle w-100">
                                         <validation-provider rules="required|min:5" v-slot="{ errors }" class="password__toggle w-100">
                                        <input :type="passshows" class="w-100"  v-model="fields.password" id="Password" placeholder="Enter password" required>
                                        <span @mousedown="passshows = 'text'" @mouseup="passshows = 'password'" class="password__toggle--btn fa fa-fw fa-eye"></span>
                                    <span class="error-msgs">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                </div>

                                 <div class="form-box__single-group m-b-20">
                                    <label for="form-register-username-password"> Confirm Password *</label>
                                    <div class="password__toggle w-100">
                                          <validation-provider rules="required|min:5" v-slot="{ errors }" class="password__toggle w-100">
                                        <input type="password"  v-model="fields.cpassword" id="Confirm Password" placeholder="Enter password" required>
                                        <span  @mousedown="passshow = 'text'" @mouseup="passshow = 'password'"  class="password__toggle--btn fa fa-fw fa-eye"></span>
                                     <span class="error-msgs">{{ errors[0] }}</span>
                                            </validation-provider>

                                    </div>
                                </div>
                                <button class="my-3 w-100 btn btn--box btn--medium btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"  @click="signup" type="button">REGISTER</button>
                            </form>
                             </ValidationObserver>
                            <div class="text-center">
                             <h4 class="or"><span>OR</span></h4>
                                <p class="subText">I have an account <router-link :to="{name:'login'}">
                                    Login Account
                                    </router-link></p>
                                    </div>
                        </div>
                    </div>
                </div> <!-- End Login Area -->
            </div>
        </div>
    </main> <!-- ::::::  End  Main Container Section  ::::::  -->

    <!-- ::::::  Start  Footer ::::::  -->
    <Footer />
    <!-- ::::::  End  Footer ::::::  -->

    <!-- material-scrolltop button -->
</body>
<!-- end of page-wrapper -->
</template>

<script>
import axios from 'axios'
import Header from '@/components/navbar'
import Footer from '@/components/footer'
import VueRecaptcha from 'vue-recaptcha';

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    components: {
        Header,
        Footer,
        VueRecaptcha,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            isHas: false,
            passshow: 'password',
            passshows: 'password',
            uid: localStorage.getItem('user'),
            reCap: false,
            fields: {
                type: 'signup',
                fullname: '',
                address: '',
                city: '',
                state: '',
                pincode: '',
                mobile: '',
                email: '',
                password: '',
                cpassword: '',
                add1: '',
                add2: '',
                mobile_no: '',
                zipcode: '',
                add_title: '',
                country: 'India',
                ip_address: JSON.parse(localStorage.getItem('ip_address')),
            },

        }
    },
    mounted() {
        this.uid = localStorage.getItem('user')
        this.check()
        this.goToTop()
    },
    methods: {
        markRecaptchaAsVerified(response) {
            this.pleaseTickRecaptchaMessage = 'This field is required';
            this.recaptchaVerified = true;
        },
        switchLoc() {
            var vali = this.$refs.form.validate();
            // if (!this.recaptchaVerified) {
            //     this.pleaseTickRecaptchaMessage = 'Please tick recaptcha.';
            //     this.reCap = true
            //     console.log(this.reCap);

            // } else {
                this.reCap = false
                vali.then(val => {
                        if (val == true) {
                            this.signup()
                        }
                    }

                )
            // }
        },
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        check() {
            if (this.uid != null) {
                window.location.href = 'myaccount';
            }
        },
        signup() {
            if (this.fields.fullname === '') {
                this.$toasted.global.error({
                    message: 'Please enter First Name'
                })
            } else if (this.fields.email === '') {
                this.$toasted.global.error({
                    message: 'Please enter Email'
                })
            } else if (this.fields.mobile === '') {
                this.$toasted.global.error({
                    message: 'Please enter Valid Mobile'
                })
            } else if (this.fields.password === '') {
                this.$toasted.global.error({
                    message: 'Please enter password'
                })
            } else if (this.fields.cpassword === '') {
                this.$toasted.global.error({
                    message: 'Please enter confirm password'
                })
            } else if (this.fields.cpassword !== this.fields.password) {
                this.$toasted.global.error({
                    message: 'Confirm password and password must be same'
                })
            } else {
                var vi = this
                vi.fields.add1 = vi.fields.address,
                    vi.fields.add2 = '',
                    vi.fields.mobile_no = vi.fields.mobile,
                    vi.fields.add_title = vi.fields.fullname,
                    console.log(vi.fields)
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: this.fields
                }).then(function (response) {
                    var data = response.data
                    if (data.status === 'success') {
                        vi.$toasted.global.success({
                            message: 'Success Please wait...'
                        })
                        vi.$router.push({
                            name: 'login'
                        });
                    } else {
                        vi.$toasted.global.error({
                            message: data.msg
                        })
                    }
                })
            }
        }
    }
}
</script>
